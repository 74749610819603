import { useState, useEffect } from 'react'
import { getAllGyms } from '../services/api/cms'
import { generalItemsPerPage } from '../services/api/constants'
import { LocationCardProps } from '../services/api/types'
import { getChoices } from '../services/utils'

function useGymOptions(stateValue?: string, postCode?: string, fields?: any, formSlug?: string) {
  const [selectedState, setSelectedState] = useState(stateValue || '')
  const [selectedPostCode, setSelectedPostCode] = useState(postCode || '')
  const setChoosenState = (state: string) => setSelectedState(state)
  const setChoosenPostCode = (postCode: string) => setSelectedPostCode(postCode)

  const gymField = fields?.filter(
    (field: any) => field.type === 'select' && field.field_block === 'gym'
  )
  const [gymOptions, setGymOptions] = useState<any>(
    getChoices(gymField && gymField[0]?.choices)
  )
  const [isLoadingGyms, setLoadingGyms] = useState(false)
  let queryParams = {
    ordering: 'title',
    per_page: generalItemsPerPage,
    fields: 'title,id',
    form_slug: formSlug
  }

  async function fetchGymData(stateValue?: string, postCode?: string) {
    const newQueryParams = stateValue || postCode
      ? {
          ...queryParams,
          address_state_code: stateValue === 'ALL' ? '' : stateValue,
          address_postcode: postCode ? postCode : ''
        }
      : queryParams

    const data = await getAllGyms(newQueryParams)
    return data.results.map((gym: LocationCardProps) => {
      return {
        label: gym.title,
        value: gym.id
      }
    })
  }

  function getGymByState(stateValue?: string, postCode?: string) {
    // Call api to get gym by state
    setLoadingGyms(true)
    fetchGymData(stateValue, postCode)
      .then(res => {
        if (res) {
          setLoadingGyms(false)
          setGymOptions(res)
        }
      })
      .catch(() => {
        setLoadingGyms(false)
      })
  }
  useEffect(() => {
    getGymByState(selectedState, selectedPostCode)
  }, [selectedState, selectedPostCode])

  return {
    gymOptions,
    isLoadingGyms,
    setChoosenState,
    setChoosenPostCode
  }
}

export default useGymOptions
