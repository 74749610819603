import * as S from '../Navigation.styles'
import * as C from './Navigation.styled'
import { IconEnums, SVGIcon } from '../../base/icon/svgIcon'
import logo_plus from '../../../static/imgs/logo_plus.png'
import { navHeaderProps, PAGE_TEMPLATE } from '../../../services/api/constants'
import Modal from '../../modal/Modal'
import { GymSearchCard } from '../../gym-search-card/GymSearchCard'
import React, { useEffect, useRef, useState } from 'react'
import { membershipEnquireButton } from '../../../services/mock'
import {
  LAYOUT_TEMPLATE,
  Link,
  MenuAPIProps,
  MenuProps,
  SiteSettingProps
} from '../../../services/api/types'
import ReactGA from 'react-ga'
import dynamic from 'next/dynamic'
import { getJoinUrlParams, isNullOrEmpty } from '../../../services/utils'
import logo_blue from '../../../static/imgs/logo_frosted.png'
import logo_white from '../../../static/imgs/logo_frosted.png'
import { NavigationProps } from '../Navigation'
import { MenuContext } from '../../../services/context/MenuContext'
import Head from 'next/head'
import HeaderTemplate from '../../header/HeaderTemplate'

const OwnerEnquireForm = dynamic(() => import('../../forms/OwnerEnquireForm'), {
  ssr: false
})

export const NavigationNewGymTemplate = (props: {
  startPostion?: 'fixed' | 'relative'
  siteName?: string
  metaType?: string
  gymId?: string
  gymTitle?: string
  joinUrl?: string
  popup_form?: boolean
  enquireUrl?: string
  enquireForm?: string
  template?: LAYOUT_TEMPLATE
}) => {
  const {
    startPostion,
    siteName,
    metaType,
    gymId,
    gymTitle,
    joinUrl,
    popup_form,
    enquireUrl,
    enquireForm
  } = props

  const [menuPostion, setMenuPostion] = useState<
    'fixed' | 'relative' | undefined
  >(startPostion)
  const ref = useRef<any>()
  const headerRef = useRef<any>()

  const [mobileOpened, setMobileOpened] = useState(false)
  const [suburbStep, setSuburbStep] = useState(true)

  const handleSuburbStep = () => {
    setSuburbStep(!suburbStep)
  }

  const mobileMenuClick = () => {
    setMobileOpened(!mobileOpened)
  }

  const handleScroll = () => {
    let height = 100 //default value

    if (headerRef && headerRef.current) {
      height = headerRef.current.clientHeight
    }
    if (window.scrollY > height) {
      setMenuPostion('fixed')
    } else {
      setMenuPostion(startPostion)
    }
  }
  const JoinNowClick = () => {
    ReactGA.event({
      category: 'Forms',
      action: 'Join now click',
      nonInteraction: true
    })
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'join_now_click'
    })
  }

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll)
    window.addEventListener('scroll', handleScroll)
  })

  useEffect(() => {
    setMenuPostion(startPostion)
  }, [startPostion])

  const membershipEnquire = {
    ...membershipEnquireButton,
    href: enquireUrl ? enquireUrl : membershipEnquireButton.href
  }

  const template = PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE

  return (
    <>
      {mobileOpened && <C.HTMLGlobalStyle />}
      <MenuContext.Consumer>
        {({ navigationData }) => {
          if (navigationData) {
            const {
              blueLogo,
              whiteLogo,
              links,
              headerProps,
              joinButton,
              ownEnquire,
              hidden
            } = navigationData

            const HeaderComponent = (
              templateType?: string,
              isMobile?: boolean
            ) => {
              let HeaderComp = HeaderTemplate(templateType)
              return (
                <>
                  {isMobile !== undefined ? (
                    <HeaderComp {...headerProps} isMobile={isMobile} />
                  ) : (
                    <HeaderComp {...headerProps} />
                  )}
                </>
              )
            }

            return (
              !hidden && (
                <>
                  <Head>
                    <link rel='preload' href={whiteLogo} as='image' />
                    <link rel='preload' href={blueLogo} as='image' />
                  </Head>
                  <S.NavigationWrapper
                    template={template}
                    navState={menuPostion}
                    ref={ref}
                  >
                    <S.HeaderWrapper
                      template={template}
                      showHeader={menuPostion !== 'fixed'}
                      ref={headerRef}
                    >
                      {HeaderComponent(PAGE_TEMPLATE.YOU_FITNESS)}
                    </S.HeaderWrapper>
                    <S.ContainerCustom id='nav'>
                      {siteName &&
                        metaType !== 'gyms.GymOpportunity' &&
                        metaType !== 'gyms.Gym' && (
                          <h1 className='hidden'>{siteName}</h1>
                        )}
                      <S.WrapMenuMobile>
                        {/* Menu for mobile display, only on mobile */}
                        {!mobileOpened && (
                          <C.MobileMenuButton
                            color='white'
                            onClick={mobileMenuClick}
                          >
                            <SVGIcon name={IconEnums.burger} preload />
                          </C.MobileMenuButton>
                        )}
                        <S.WrapLogo href='/'>
                          <S.DesktopLogo
                            src={logo_plus}
                            alt={siteName}
                            menuPosition={menuPostion}
                          />
                          {!mobileOpened && (
                            <C.MobileLogo src={logo_plus} alt={siteName} />
                          )}{' '}
                        </S.WrapLogo>
                      </S.WrapMenuMobile>
                      <C.DesktopMenus>
                        {links &&
                          links.map((l, i) => (
                            <C.Menu key={i} href={l.url}>
                              {l.label}
                            </C.Menu>
                          ))}
                      </C.DesktopMenus>
                      <S.WrapEnquireJoinNow>
                        {!ownEnquire && (
                          <C.EnquireButtonWhite
                            {...membershipEnquire}
                            href={
                              gymId && gymTitle
                                ? `${membershipEnquire.href}?gymId=${gymId}&gymTitle=${gymTitle}&goto=${enquireForm}`
                                : `${membershipEnquire.href}?goto=${enquireForm}`
                            }
                          >
                            {membershipEnquire.label}
                          </C.EnquireButtonWhite>
                        )}
                        {ownEnquire ? (
                          <Modal
                            showCloseIcon={true}
                            buttonText='Enquire Now'
                            title='Enquire Form'
                            template={template}
                          >
                            <OwnerEnquireForm
                              {...ownEnquire}
                              template={template}
                            />
                          </Modal>
                        ) : joinUrl && !popup_form ? (
                          <C.JoinButton
                            href={getJoinUrlParams(joinUrl, gymTitle)}
                            onClick={JoinNowClick}
                          >
                            {joinButton?.label}
                          </C.JoinButton>
                        ) : (
                          <Modal
                            template={template}
                            showCloseIcon={!!suburbStep}
                            buttonText={joinButton?.label}
                            cancelText={!suburbStep ? 'Cancel' : ''}
                            navState={menuPostion}
                          >
                            <GymSearchCard
                              template={template}
                              suburbStep={suburbStep}
                              handleSuburbStep={handleSuburbStep}
                              gym={gymId}
                            />
                          </Modal>
                        )}
                      </S.WrapEnquireJoinNow>
                      <S.MobileWrapper
                        className={`${!mobileOpened && 'hidden'}`}
                        {...{ isShow: mobileOpened }}
                      >
                        <S.MobileActionsNew
                          showHeader={menuPostion !== 'fixed'}
                          ref={headerRef}
                        >
                          {HeaderComponent(template)}
                        </S.MobileActionsNew>
                        <C.HeaderGroup>
                          <S.ContainerCustom>
                            <C.HeaderGroupInner>
                              <C.MobileMenuButton onClick={mobileMenuClick}>
                                <SVGIcon name={IconEnums.close} />
                              </C.MobileMenuButton>
                              <S.WrapLogo href='/'>
                                <C.MobileLogo
                                  src={logo_plus}
                                  alt={siteName}
                                  width='100%'
                                />
                              </S.WrapLogo>
                            </C.HeaderGroupInner>
                            {ownEnquire ? (
                              <Modal
                                showCloseIcon={true}
                                buttonText='Enquire Now'
                                title='Enquire Form'
                                template={template}
                              >
                                <OwnerEnquireForm
                                  {...ownEnquire}
                                  template={template}
                                />
                              </Modal>
                            ) : metaType === 'gyms.Gym' &&
                              joinUrl &&
                              !popup_form ? (
                              <C.JoinButton
                                href={getJoinUrlParams(joinUrl, gymTitle)}
                                onClick={JoinNowClick}
                              >
                                {joinButton?.label}
                              </C.JoinButton>
                            ) : (
                              <Modal
                                template={template}
                                showCloseIcon={!!suburbStep}
                                buttonText={joinButton?.label}
                                cancelText={!suburbStep ? 'Cancel' : ''}
                                navState={menuPostion}
                              >
                                <GymSearchCard
                                  template={template}
                                  suburbStep={suburbStep}
                                  handleSuburbStep={handleSuburbStep}
                                />
                              </Modal>
                            )}
                          </S.ContainerCustom>
                        </C.HeaderGroup>
                        <S.MobileMenusWrapper className='bg-black__deep'>
                          <S.Container className={'h-full w-full'}>
                            <S.MobileMenus>
                              <S.MobileLinks>
                                {links &&
                                  links.map((l, i) => (
                                    <C.MobileLink key={i} href={l.url}>
                                      {l.label}
                                    </C.MobileLink>
                                  ))}
                              </S.MobileLinks>
                              <S.MobileButtonWrapper>
                                {!ownEnquire && (
                                  <C.EnquireButton
                                    {...membershipEnquire}
                                    href={
                                      gymId && gymTitle
                                        ? `${membershipEnquire.href}?gymId=${gymId}&gymTitle=${gymTitle}&goto=${enquireForm}`
                                        : `${membershipEnquire.href}?goto=${enquireForm}`
                                    }
                                  >
                                    {membershipEnquire.label}
                                  </C.EnquireButton>
                                )}
                              </S.MobileButtonWrapper>
                            </S.MobileMenus>
                          </S.Container>
                        </S.MobileMenusWrapper>
                      </S.MobileWrapper>
                    </S.ContainerCustom>
                  </S.NavigationWrapper>
                </>
              )
            )
          } else {
            return null
          }
        }}
      </MenuContext.Consumer>
      {/* keep page height same when menu change position to fixed to prevent page flash with the page have height same as viewport */}
      {menuPostion == 'fixed' && (
        <div
          style={{
            width: '100%',
            minHeight: ref ? ref.current?.clientHeight : 0
          }}
        />
      )}
    </>
  )
}

NavigationNewGymTemplate.ApiBlockToProps = (
  menuItems: MenuAPIProps,
  siteSettings: SiteSettingProps
): NavigationProps => {
  let links = new Array<Link>()

  if (menuItems) {
    links =
      menuItems.items &&
      menuItems.items[0].menu_items.map((link: MenuProps) => {
        return {
          ...link,
          label: link.link_text || link.label
        }
      })
  }

  // Get the header call to action text from site settings if available
  let headerProps = { ...navHeaderProps }
  if (!isNullOrEmpty(siteSettings.search_banner_text_left)) {
    headerProps.CTATitle = siteSettings.search_banner_text_left
  }

  let headerTitles: [string, string] = navHeaderProps.Titles

  if (!isNullOrEmpty(siteSettings.search_banner_text_right_orange)) {
    headerTitles[0] = siteSettings.search_banner_text_right_orange
  }

  if (!isNullOrEmpty(siteSettings.search_banner_text_right_white)) {
    headerTitles[1] = siteSettings.search_banner_text_right_white
  }

  headerProps.Titles = headerTitles

  return {
    links: links,
    // TODO Logic for these
    blueLogo: logo_blue,
    whiteLogo: logo_white,
    joinButton: {
      label: 'Join now'
    },
    headerProps: headerProps
  }
}

export default NavigationNewGymTemplate
