import * as S from '../Footer.styled'
import * as G from '../../../styles/global.styles'
import * as C from './Footer.styled'
import { Fragment, useContext } from 'react'
import {
  Iconlink,
  Link,
  MenuAPIProps,
  SiteSettingProps
} from '../../../services/api/types'
import { IconEnums } from '../../base'
import { sampleFooterProps } from '../../../services/mock'
import { MenuContext } from '../../../services/context/MenuContext'
import { FooterProps } from '../Footer'
import { SiteContext } from '../../../services/context/SiteContext'
import { getCountryFromCode } from '../../../services/api/helpers'

export interface FooterNewGymTemplateProps {
  logoNew: string
  iconFooter: string
  copyright: string
  links: Link[]
  social: {
    label: string
    socialLinks: Iconlink[]
    newSocialLinks: Iconlink[]
  }
  siteLinks: Link[]
}

export const FooterNewGymTemplate = () => {
  const { siteSettings } = useContext(SiteContext)

  return (
    <MenuContext.Consumer>
      {({ flatMenuData }) => {
        if (flatMenuData) {
          const {
            copyright,
            links,
            social,
            siteLinks,
            logoNew,
            iconFooter
          } = flatMenuData

          return (
            <C.NewLayoutFooter>
              <C.NewLayoutFooterLeft>
                <C.NewLayoutFooterLogo href='/'>
                  <figure>
                    <G.DefaultImg src={logoNew} />
                  </figure>
                </C.NewLayoutFooterLogo>
                <C.NewLayoutFooterLinksGroup>
                  {links &&
                    links.map((link, idx) => (
                      <Fragment key={idx}>
                        <C.NewLayoutFooterLink
                          href={link.url}
                          target={link.target}
                        >
                          {link.label}
                        </C.NewLayoutFooterLink>
                      </Fragment>
                    ))}
                  {siteLinks &&
                    siteLinks.map((link, ix) => (
                      <Fragment key={ix}>
                            {link.label != 'Plus fitness franchising' && (
                        <C.NewLayoutFooterLink
                          href={link.url}
                          target={link.target}
                        >
                          {link.label}
                        </C.NewLayoutFooterLink>
                      )}                        
                      </Fragment>
                    ))}
                </C.NewLayoutFooterLinksGroup>
              </C.NewLayoutFooterLeft>
              <C.NewLayoutFooterRight>
                <C.NewLayoutFooterIcon>
                  <G.DefaultImg src={iconFooter} />
                </C.NewLayoutFooterIcon>
                <C.NewLayoutSocialGroup>
                  <C.NewLayoutSocialLabel>
                    {social.label}
                  </C.NewLayoutSocialLabel>
                  <C.NewLayoutSocialIcons>
                    {social.newSocialLinks &&
                      social.newSocialLinks.map(
                        (link, idx) =>
                          link.url && (
                            <G.Link key={idx} href={link.url}>
                              <S.Icon name={link.icon} />
                            </G.Link>
                          )
                      )}
                  </C.NewLayoutSocialIcons>
                </C.NewLayoutSocialGroup>
              </C.NewLayoutFooterRight>
              <C.NewLayoutCopyright>
              {siteSettings?.show_address_in_footer ? (
                      <S.Address>
                        {siteSettings.address_street}{' '}
                        {siteSettings.address_suburb}{' '}
                        {siteSettings.address_state_code}{' '}
                        {getCountryFromCode(siteSettings.address_country)}{' '}
                        {siteSettings.address_postcode}
                      </S.Address>
                    ) : null}                     
                <br />
                {copyright}</C.NewLayoutCopyright>
            </C.NewLayoutFooter>
          )
        }
      }}
    </MenuContext.Consumer>
  )
}

FooterNewGymTemplate.ApiBlockToProps = (
  menuItems?: MenuAPIProps,
  siteSettings?: SiteSettingProps,
  siteLinks?: Link[]
): FooterProps => {
  let links = new Array<Link>()

  if (menuItems && menuItems.items) {
    const { items } = menuItems
    links = items[0].menu_items?.map((link: any) => {
      return {
        ...link,
        label: link.link_text
      }
    })
  }

  return {
    links: links,
    siteLinks: siteLinks || [],
    social: {
      label: 'Follow Us',
      socialLinks: [
        {
          icon: IconEnums['icon-whatsapp'],
          url: siteSettings?.whatsapp || ''
        },
        {
          icon: IconEnums['facebook-filled'],
          url: siteSettings?.facebook || ''
        },
        {
          icon: IconEnums['threads-filled'],
          url: siteSettings?.threads || ''
        },
        {
          icon: IconEnums['twitter-filled'],
          url: siteSettings?.twitter || ''
        },
        {
          icon: IconEnums['linkedin-filled'],
          url: siteSettings?.linkedin || ''
        },
        {
          icon: IconEnums['instagram-filled'],
          url: siteSettings?.instagram || ''
        },
        {
          icon: IconEnums['youtube-filled'],
          url: siteSettings?.youtube || ''
        }
      ],
      newSocialLinks: [
        {
          icon: IconEnums['icon-whatsapp'],
          url: siteSettings?.whatsapp || ''
        },
        {
          icon: IconEnums['icon-fb2'],
          url: siteSettings?.facebook || ''
        },
        {
          icon: IconEnums['threads-filled'],
          url: siteSettings?.threads || ''
        },
        {
          icon: IconEnums['icon-instagram2'],
          url: siteSettings?.instagram || ''
        },
        {
          icon: IconEnums['icon-youtube2'],
          url: siteSettings?.youtube || ''
        }
      ]
    },
    logo: sampleFooterProps.logo,
    partner: sampleFooterProps.partner,
    partnerLink: sampleFooterProps.partnerLink,
    copyright: sampleFooterProps.copyright,
    logoNew: sampleFooterProps.logoNew,
    iconFooter: sampleFooterProps.iconFooter
  }
}

export default FooterNewGymTemplate
