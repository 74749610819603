import styled, { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'
import { fontSize, size } from '../../../styles/theme'

export const HTMLGlobalStyle = createGlobalStyle`
  html, body {
    ${tw`h-full overflow-hidden`}

    .h-top {
      ${tw`hidden`}
    }
  }
`

export const MobileMenuButton = styled.button`
  ${tw`xl:hidden italic text-xl`}
  margin-right: ${size(16)};
  ${({ color }) => color == 'orange' && tw`text-orange`};
  ${({ color }) => (!color || color == 'white') && tw`text-white`};

  /* Extra Large (xl) */
  @media (max-width: 480px) {
    ${tw`text-xs`}
  }
`

export const MobileLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  /* Extra Large (xl) */
  @media (min-width: 1280px) {
  }
  ${tw`lg:w-auto xl:hidden`};
`

export const Menu = styled.a`
  ${tw`py-2 px-2 text-center font-bold text-white hover:text-blue transition-colors duration-300`}
  ${fontSize(14)}
`

export const MobileLink = styled.a`
  ${tw`py-3 font-semibold text-white hover:text-blue transition-colors duration-300 text-xl`}
`

export const HeaderGroup = styled.div`
  ${tw`w-full flex flex-row justify-between transition-all duration-300 bg-black__deep`}

  .mobile-container {
    padding: 16px 16px;
  }
`

export const HeaderGroupInner = styled.div`
  ${tw`flex justify-start transition-all duration-300 bg-black__deep`}
`

export const DesktopMenus = styled.div`
  ${tw`hidden xl:flex lg:flex-wrap flex-1 justify-between pl-12`}
  width: 100%;
  max-width: 600px;
`

export const EnquireButton = styled.a`
  ${tw`border text-sm border-blue__dark px-4 py-2 font-bold text-white hover:bg-blue__dark transition-colors duration-300`}

  @media (max-width: 1279px) {
    ${tw`border-white px-4 py-2 font-bold text-black bg-white hover:bg-gray transition-colors duration-300`}
    padding: 0.57rem 2rem;
  }
`

export const EnquireButtonWhite = styled.a`
  ${tw`bg-white text-sm h-10 text-black ml-4 transition-colors duration-300`}
  padding: 0.57rem 2rem;

  &:hover {
    background-color: #ccc !important;
  }

  /* Extra Large (xl) */
  @media (max-width: 1279px) {
    display: none;
  }
`

export const JoinButton = styled.a`
  ${tw`border-white h-10 font-normal ml-4 text-sm cursor-pointer inline-block text-white bg-blue__deep border-blue__deep hover:bg-blue__dark2 hover:border-blue__dark2`}
  padding: 0.57rem 2rem;
`
